import React, { useState } from "react";
import {
  DialogTrigger,
  Dialog,
  Heading,
  Button,
  Modal,
} from "react-aria-components";
import { FaEye } from "react-icons/fa";
import ArticleMedia from "./ArticleMedia"; // Assurez-vous que ce chemin est correct

const RowOptionsMenuTriggerMedia = ({ onImageSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageSelect = (imageUrl) => {
    onImageSelected(imageUrl);
    setSelectedImage(imageUrl);
    setIsOpen(false);
  };

  return (
    <DialogTrigger>
      <Button
        className="flex items-center border border-gray-300 gap-5 rounded px-2 uppercase font-bold text-gray-700"
        onClick={() => setIsOpen(true)}
      >
        <FaEye /> Voir les Medias
      </Button>
      <Modal
        className="fixed top-0 left-0 right-0 bottom-0 z-[1000] bg-[#212121] p-2 rounded overflow-hidden"
        isOpen={isOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsOpen(false);
        }}
      >
        <Dialog className="w-full overflow-hidden">
          {({ close }) => (
            <div className="relative w-full h-[98vh] flex flex-col">
              <div className="flex-1 overflow-auto">
                <ArticleMedia onSelect={handleImageSelect} />
              </div>

              <Button
                className="absolute top-5 shadow font-semibold right-5 px-3 py-1 text-white rounded bg-red-700 hover:bg-red-500 transition-bg duration-500"
                onPress={close}
              >
                Fermer
              </Button>
            </div>
          )}
        </Dialog>
      </Modal>
    </DialogTrigger>
  );
};

export default RowOptionsMenuTriggerMedia;
