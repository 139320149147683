import React, { useEffect, useState } from "react";
import GalleryTable from "./GalleryTable";
import ImageForm from "./ImageForm";
import ConfirmationModal from "../../../../Pages/dashBoard/containers/ConfirmationModal"; // Import the existing modal
import {
  getAllGallery,
  setStatusImage,
  deleteImage,
} from "../../../../services/services";
import {
  Button,
  Input,
  Label,
  SearchField,
  Switch,
} from "react-aria-components";

import { useFilter } from "react-aria";

const Gallery = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  const handleAddImage = (image) => {
    const { data: submittedData } = image;
    const updatedData = [...data, submittedData];
    setData(updatedData);
  };

  const handleUpdateStatus = async (image) => {
    const { _id, validated } = image;

    try {
      const { response } = await setStatusImage(_id, {
        validated,
      });

      if (response.status === 200) {
        const updatedData = data.map((item) =>
          item._id === _id ? { ...item, validated: !validated } : item,
        );
        setData(updatedData);
      } else {
        console.error("Échec de la mise à jour du statut");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut :", error);
    }
  };

  const handleDelete = async () => {
    if (!imageToDelete) return;

    try {
      const { response } = await deleteImage(imageToDelete._id);

      if (response.status === 200) {
        const updatedData = data.filter(
          (item) => item._id !== imageToDelete._id,
        );
        setData(updatedData);
        setIsModalOpen(false);
      } else {
        console.error("Échec de la suppression de l'image");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de l'image :", error);
    }
  };

  const openDeleteModal = (image) => {
    setImageToDelete(image);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setImageToDelete(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      const { data: fetchedData, response } = await getAllGallery();
      if (response.status === 200) {
        setData(fetchedData);
      }
    })();
  }, []);
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSizeOptions = [25, 50, 75];
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  let { contains } = useFilter({
    sensitivity: "base",
  });

  let matchedComposers = data.filter((composer) => {
    const searchTerm = value.toLowerCase().trim();

    const matchesName =
      searchTerm.length > 3
        ? composer.name.toLowerCase().includes(searchTerm)
        : true;

    const matchesAuthors =
      searchTerm.length > 3
        ? composer.author.some((author) =>
            author.toLowerCase().includes(searchTerm),
          )
        : true;

    const matchesTags =
      searchTerm.length > 3
        ? composer.tags.some((tag) => tag.toLowerCase().includes(searchTerm))
        : true;

    return matchesName || matchesAuthors || matchesTags;
  });

  const totalItems = matchedComposers.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    if (page === 0) return setCurrentPage(1);
    if (page > totalPages) return setCurrentPage(totalPages);
    setCurrentPage(page);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="space-y-8">
        <div className="p-4 bg-gray-100 rounded">
          <h1 className="text-xl font-bold mb-4">Ajouter une Image</h1>
          <ImageForm onSubmit={handleAddImage} />
        </div>
        <div className="p-4 bg-gray-100 rounded">
          <h1 className="text-xl font-bold mb-4">Galerie</h1>

          <section className="bg-gray-100 w-full p-2 overflow-hidden">
            <div className="flex gap-3 items-center justify-between w-full">
              <SearchField className="flex items-center p-2">
                <Label className="mr-2 text-black font-semibold">
                  Rechercher
                </Label>
                <Input
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  placeholder="Entrez le nom d'un media..."
                />
                <Button
                  onPress={() => setValue("")}
                  className="ml-2 p-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none"
                  aria-label="Clear search"
                >
                  ✕
                </Button>
              </SearchField>

              <div className="flex items-center gap-3 text-white">
                <Label className="font-semibold text-black text-right pl-2">
                  Résultat:{" "}
                </Label>
                <p className="center text-black px-2 rounded-md border-[#8f8f8f] border p-2">
                  {matchedComposers.length} / {data.length} Medias
                </p>
              </div>
            </div>

            <section className="shadowCustom w-full h-[75vh] mt-2 rounded max-h-[73vh] !overflow-scroll">
              <GalleryTable
                data={matchedComposers}
                pageSize={pageSize}
                currentPage={currentPage}
                onUpdateStatus={handleUpdateStatus}
                onDelete={openDeleteModal} // Pass the function to open the modal
              />
            </section>
            <div className="flex justify-between mt-2">
              <div>
                <Label className="text-black">Éléments par page: </Label>
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  className="text-black px-2 rounded-md border-[#8f8f8f] border"
                >
                  {pageSizeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2 text-back">
                  <p className="">{`Page `}</p>
                  <Input
                    className="bg-transparent w-8 text-center border border-black rounded outline-none"
                    value={currentPage}
                    onChange={(e) => {
                      handlePageChange(Number(e.target.value));
                    }}
                  />
                  <p className="">{` / ${totalPages}`}</p>
                </div>

                <Button
                  onPress={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="text-black px-2 rounded-md border-[#8f8f8f] border"
                >
                  Précédent
                </Button>
                <Button
                  onPress={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="text-black px-2 rounded-md border-[#8f8f8f] border"
                >
                  Suivant
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* Confirmation Modal */}
      {imageToDelete && (
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDelete}
          name={imageToDelete}
        />
      )}
    </div>
  );
};

export default Gallery;
